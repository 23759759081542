






































































































































































































































































import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref,
  Watch
} from "vue-property-decorator";
import { VForm } from "@/types";

@Component
export default class Header extends Vue {
  refs!: any;
  isLoaded: boolean = false;
  isModal: boolean = false;
  isDesktopDisplay: boolean = true;
  isMobileDisplay: boolean = false;
  isDisplay: boolean = false;

  @Prop() showAllIntegration!: Boolean;
  @Prop() showAllPricing!: Boolean;
  @Prop() showAllSocialMediaOrdering!: Boolean;

  constructor() {
    super();
    this.refs = this.$parent.$refs;
  }
  mounted() {
    this.isLoaded = true;
    this.isModal = false;
    this.showRequestDemo();
    this.showLogo();
  }

  showLogo() {
    if (!this.isMobile()) {
      this.isDesktopDisplay = true;
      this.isMobileDisplay = false;
    } else {
      this.isDesktopDisplay = false;
      this.isMobileDisplay = true;
    }
  }

  showRequestDemo() {
    if (!this.isMobile()) {
      this.isDisplay = false;
    } else {
      this.isDisplay = true;
    }
  }

  get homeIsActive(): boolean {
    this.isModal = false;
    return this.refs.landing.isActive;
  }

  get introductionIsActive(): boolean {
    this.isModal = false;
    return this.refs.introduction.isActive;
  }

  get socialIsActive(): boolean {
    this.isModal = false;
    return this.refs.social.isActive;
  }

  get soloappIsActive(): boolean {
    this.isModal = false;
    return this.refs.soloApp.isActive;
  }

  get soloKioskIsActive(): boolean {
    this.isModal = false;
    return this.refs.soloKiosk.isActive;
  }

  get clientsIsActive(): boolean {
    this.isModal = false;
    return this.refs.clients.isActive;
  }

  get integrationIsActive(): boolean {
    this.isModal = false;
    return this.refs.integration.isActive;
  }

  get pricingIsActive(): boolean {
    this.isModal = false;
    return this.refs.pricing.isActive;
  }


  scrollTO(e: HTMLFormElement, num: number = 200) {
    if (this.showAllIntegration || this.showAllPricing || this.showAllSocialMediaOrdering) {
      let data = {
        e: e,
        num: num
      };
      this.$emit("hide-all:integration", data);
      this.$emit("hide-all:pricing", data);
      this.$emit("hide-all:social-media-ordering", data);
    } else {
      if (window.screen.width >= 1200) {
        window.scrollTo({
          top: e.$el.offsetTop - num,
          left: 0,
          behavior: "smooth"
        });
      } else {
        window.scrollTo({
          top: e.$el.offsetTop + num,
          left: 0,
          behavior: "smooth"
        });
      }
    }
  }

  toggleMenu(e: HTMLFormElement) {
    if (!this.isMobile()) {
      this.isModal = false;
    } else {
      this.isModal = true;
    }
  }

  closeToggleMenu(e: HTMLFormElement) {
    if (!this.isMobile()) {
      this.isModal = false;
    } else {
      this.isModal = false;
    }
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
