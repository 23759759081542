




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Body1 from "@/components/BodyPricing.vue";
import Header1 from "@/components/Header1.vue";
import Footer from "@/components/Footer1.vue";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref
} from "vue-property-decorator";

@Component({
  components: {
    Body1,
    Header1,
    Footer
  }
})
export default class AllPricing extends Vue {
  show: boolean = false;
  discount: boolean = false;
  discountPrize: boolean = false;
  isDesktopDisplay: boolean = true;
  isMobileDisplay: boolean = false;
  seeMore: boolean = false;
  seeMorePremium: boolean = false;
  seeMoreEnterprise: boolean = false;
  constructor() {
    super();
  }
  @Ref() body1!: HTMLElement;
  @Ref() header1!: HTMLElement;
  @Ref() footer!: HTMLElement;


  mounted() {
    this.showPricingMobile();
  }

  private goToPortal(num: number) {
    window.location.replace(`http://app.getsolo.io/register/${num}?billing-cycle=month`)
  }

  private goToPortalDiscounted(num: number) {
    window.location.replace(`http://app.getsolo.io/register/${num}?billing-cycle=year`)
  }

  showMore() {
    this.seeMore = true;
  }
  hideDetails() {
    this.seeMore = false;
  }
  showMorePremium() {
    this.seeMorePremium = true;
  }
  hideDetailsPremium() {
    this.seeMorePremium = false;
  }
   showMoreEnterprise() {
    this.seeMoreEnterprise = true;
  }
  hideDetailsEnterprise() {
    this.seeMoreEnterprise = false;
  }


  discounted() {
    if(this.discount === true) {
      this.discountPrize = true;
      return false;
    }
    console.log("false");
    this.discountPrize = false;
  }


  showPricingMobile() {
     if (!this.isMobile()) {
      this.isDesktopDisplay = true;
      this.isMobileDisplay = false;
    } else {
      this.isDesktopDisplay = false;
      this.isMobileDisplay = true;
    }
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }


}
