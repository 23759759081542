






















































import { VForm } from "@/types";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref,
  Watch
} from "vue-property-decorator";
import { ArraySlider, NavigateObject } from "@/interfaces/slider";
@Component
export default class LandingPage extends Vue {
  root: any = this.$root;
  isActive: boolean = false;
  isLoaded: boolean = false;
  animateSection: boolean = false;
  hideContent: boolean = true;
  deductionOnTop: number = 400;
  deductionOnBottom: number = 200;
  activeIndex: number = 0;
  activeIndexIndicator: number = 0;
  autoScroll: boolean = true;
  disabled: NavigateObject = {
    next: false,
    prev: false
  };
  // Attract and retain customers for your restaurant, coffee shop or bakery
  // Get your orders easily<br> from Instragram, WhatsApp and Twitter
  // Increase your online sales with Solo&#8217s unrivalled suite of marketing tools
  // 24/7 control of your account and visibility of KPIs
  // Attract and retain customers with high quality mobile apps and websites
  // Reduce costs and increase sales with in-store self-service kiosks
  @Ref() slider!: any;
  sliderContents: ArraySlider = [
    {
      content: '<p class="content"></p>'
    },
    {
      content: '<p class="content"></p>'
    },
    {
      content: '<p class="content"></p>'
    },
    {
      content: '<p class="content"></p>'
    },
    {
      content: '<p class="content"></p>'
    },
    {
      content: '<p class="content"></p>'
    }
  ];
  constructor() {
    super();
    this.slider = this.$refs.slider;
  }
  mounted() {
    this.navigateNext();
  }
  @Watch("activeIndex")
  logIndex(newVal: number, oldVal: string) {}
  slideIndex(i: number) {
    if (this.activeIndex != i) this.activeIndex = i;
    this.activeIndexIndicator = i;
  }

  @Watch("windowtop", { immediate: true, deep: true })
  onScrollChanged(val: number, oldVal: string) {
    if (this.isLoaded) {
      this.checkScrollTop(val, this);
    }
  }
  get windowtop(): VForm {
    return this.root.windowtop;
  }
  checkScrollTop(parentScroll: number = 0, componentScroll: any) {
    if (
      parentScroll >= componentScroll.$el.offsetTop - this.deductionOnTop &&
      parentScroll <=
        componentScroll.$el.offsetTop +
          componentScroll.$el.clientHeight -
          this.deductionOnBottom
    )
      this.animate(parentScroll, componentScroll.offsetTop);
    else this.stopAnimation();
  }

  animate(parentScroll: number, componentScroll: number) {
    this.animateSection = true;
    this.hideContent = false;
    this.isActive = true;
  }

  stopAnimation() {
    this.animateSection = false;
    this.hideContent = true;
    this.isActive = false;
  }

  navigateNext() {
    if (this.autoScroll)
      setInterval(() => {
        this.nextSlide();
      }, 5000);
  }

  disableNavBtn(num: boolean = true) {
    if (num) {
      this.disabled.prev = true;
      this.disabled.next = true;
    } else {
      this.disabled.prev = false;
      this.disabled.next = false;
    }
  }

  previousSlide() {
    if (this.activeIndex) {
      this.disableNavBtn();
      this.slider[this.activeIndex].style.position = "absolute";
      this.slider[this.activeIndex].style.marginLeft = "100%";
      this.slider[this.activeIndex - 1].style.marginLeft = "100%";
      this.activeIndexIndicator--;
      setTimeout(() => {
        this.activeIndex--;
        this.slider[this.activeIndex + 1].style.position = "";
        this.slider[this.activeIndex + 1].style.marginLeft = "";
        this.slider[this.activeIndex].style.transition = "none";
        this.slider[this.activeIndex].style.right = "";
        this.slider[this.activeIndex].style.marginLeft = "";
        this.slider[this.activeIndex].style.display = "";
        this.disableNavBtn(false);
        setTimeout(() => {
          this.slider[this.activeIndex].style.transition = "";
        }, 50);
      }, 500);
    } else {
      // this.activeIndex = this.sliderContents.length - 1;
      // this.activeIndexIndicator = this.sliderContents.length - 1;
      this.slider[0].style.marginLeft = "-100%";
      this.activeIndexIndicator = 5;
      setTimeout(() => {
        this.activeIndex = 5;
        this.slider[0].style.marginLeft = "";
        this.slider[this.activeIndex].style.transition = "none";
        this.slider[this.activeIndex].style.right = "";
        this.slider[this.activeIndex].style.marginLeft = "";
        this.slider[this.activeIndex].style.display = "";
        this.disableNavBtn(false);
        setTimeout(() => {
          this.slider[this.activeIndex].style.transition = "";
        }, 50);
      }, 500);
    }
  }
  nextSlide() {
    if (this.activeIndex < this.sliderContents.length - 1) {
      this.disableNavBtn();
      this.slider[this.activeIndex].style.marginLeft = "-100%";
      this.slider[this.activeIndex + 1].style.marginLeft = "-100%";
      this.activeIndexIndicator++;
      setTimeout(() => {
        this.activeIndex++;
        this.slider[this.activeIndex - 1].style.marginLeft = "";
        this.slider[this.activeIndex].style.transition = "none";
        this.slider[this.activeIndex].style.right = "";
        this.slider[this.activeIndex].style.marginLeft = "";
        this.slider[this.activeIndex].style.display = "";
        this.disableNavBtn(false);
        setTimeout(() => {
          this.slider[this.activeIndex].style.transition = "";
        }, 50);
      }, 500);
    } else {
      this.slider[6].style.marginLeft = "-100%";
      this.activeIndexIndicator = 0;
      setTimeout(() => {
        this.activeIndex = 0;
        this.slider[6].style.marginLeft = "";
        this.slider[this.activeIndex].style.transition = "none";
        this.slider[this.activeIndex].style.right = "";
        this.slider[this.activeIndex].style.marginLeft = "";
        this.slider[this.activeIndex].style.display = "";
        this.disableNavBtn(false);
        setTimeout(() => {
          this.slider[this.activeIndex].style.transition = "";
        }, 50);
      }, 500);
    }
  }
}
