import BaseService from '../base.service'
import store from '@/store'
export default class SendGrid extends BaseService {

    constructor() {
        super();        
        this.baseURL = 'https://smtpjs.com/v3/smtpjs.aspx?'        
    }

    send(data: Object = {}) {
      
      return this.postMail(this.baseURL, data)
    }
}
