

















import Header from "@/components/Header.vue";
import Body from "@/components/Body.vue";
import LandingPAge from "@/components/LandingPage.vue";
import SocialMediaOrdering from "@/components/SocialMediaOrdering.vue";
import SoloKiosk from "@/components/SoloKiosk.vue";
import SoloApp from "@/components/SoloApp.vue";
import Introduction from "@/components/Introduction.vue";
import OurClients from "@/components/OurClients.vue";
import OurIntegration from "@/components/OurIntegration.vue";
import Pricing from "@/components/Pricing.vue";
import AllIntegration from "@/components/AllIntegration.vue";
import AllPricing from "@/components/AllPricing.vue";
import AllSocialMediaOrdering from "@/components/AllSocialMediaOrdering.vue";
import RequestDemo from "@/components/RequestDemo.vue";
import Footer from "@/components/Footer.vue";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref
} from "vue-property-decorator";

@Component({
  components: {
    Header,
    Body,
    SocialMediaOrdering,
    SoloKiosk,
    SoloApp,
    LandingPAge,
    Introduction,
    OurClients,
    OurIntegration,
    Pricing,
    RequestDemo,
    Footer
  }
})
export default class Home extends Vue {

  @Ref() header!: HTMLElement;
  @Ref() body!: HTMLElement;
  @Ref() social!: HTMLElement;
  @Ref() landing!: HTMLElement;
  @Ref() introduction!: HTMLElement;
  @Ref() soloKiosk!: HTMLElement;
  @Ref() soloApp!: HTMLElement;
  @Ref() clients!: HTMLElement;
  @Ref() integration!: HTMLElement;
  @Ref() pricing!: HTMLElement;
  @Ref() requestDemo!: HTMLElement;
  @Ref() footer!: HTMLElement;
}
