























































































































































































































































import { VForm } from "@/types";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Watch,
  Ref
} from "vue-property-decorator";
@Component
export default class Pricing extends Vue {
  root: any = this.$root;
  element: any;
  show: boolean = false;
  discount: boolean = false;
  discountPrize: boolean = false;
  discountStandard: boolean = false;
  isLoaded: boolean = false;
  animateSection: boolean = false;
  hideContent: boolean = true;
  deductionOnTop: number = 400;
  deductionOnBottom: number = 200;
  animateContent: Boolean = true;
  isDesktopDisplay: boolean = true;
  isMobileDisplay: boolean = false;
  isActive: boolean = false;
  constructor() {
    super();
  }
  mounted() {
    this.element = this.$el;
    this.isLoaded = true;
    this.showPricingMobile();
  }


  private goToPortal(num: number) {
    console.log(num)
    window.location.replace(`http://app.getsolo.io/register/${num}?billing-cycle=month`)
  }

   private goToPortalDiscounted(num: number) {
    console.log(num)
    window.location.replace(`http://app.getsolo.io/register/${num}?billing-cycle=year`)
  }

 

  showPricingMobile() {
     if (!this.isMobile()) {
      this.isDesktopDisplay = true;
      this.isMobileDisplay = false;
    } else {
      this.isDesktopDisplay = false;
      this.isMobileDisplay = true;
    }
  }

  discounted() {
    if(this.discount === true) {
      this.discountPrize = true;
      return false;
    }
    this.discountPrize = false;
  }

  @Watch("windowtop", { immediate: true, deep: true })
  onScrollChanged(val: number, oldVal: string) {
    if (this.isLoaded) {
      this.checkScrollTop(val, this);
    }
  }

  get windowtop(): VForm {
    return this.root.windowtop;
  }

  checkScrollTop(parentScroll: number = 0, componentScroll: any) {
    if (
      parentScroll >= componentScroll.$el.offsetTop - this.deductionOnTop &&
      parentScroll <=
        componentScroll.$el.offsetTop +
          componentScroll.$el.clientHeight -
          this.deductionOnBottom
    ) {
      this.isActive = true;
      if (this.animateContent) {
        this.animate(parentScroll, componentScroll.offsetTop);
        this.animateContent = false;
      } else {
        this.stopAnimation();
      }
    } else {
      this.isActive = false;
    }
  }

  animate(parentScroll: number, componentScroll: number) {
    this.animateSection = true;
    this.hideContent = false;
  }

  stopAnimation() {
    this.animateSection = false;
    this.hideContent = true;
  }
  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
