



























































































































































































































































































































































































import { VForm } from "@/types";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref,
  Watch
} from "vue-property-decorator";
import {
  validate,
  validateOrReject,
  Contains,
  IsInt,
  Length,
  IsEmail,
  IsFQDN,
  IsDate,
  Min,
  Max,
  isMobilePhone
} from "class-validator";
import { RequestDemoForm, RequestDemoFormObject } from "@/interfaces/forms";
import { sendGrid } from "@/services/Mail";
import { required, email } from "vuelidate/lib/validators";
import CountryFlag from "vue-country-flag";

@Component({
  validations: {
    customername: {
      required
    },
    business: {
      required
    },
    mobile: {
      required
    },
    email: {
      required,
      email
    }
  },
  components: {
    CountryFlag
  }
})
export default class RequestDemo extends Vue {
  root: any = this.$root;
  element: any;
  isLoaded: boolean = false;
  animateSection: boolean = false;
  hideContent: boolean = true;
  deductionOnTop: number = 400;
  deductionOnBottom: number = 200;
  size: number = 0;
  customername: string = "";
  business: string = "";
  mobile: string = "";
  email: string = "";
  country_code: string = "";
  phone_code: string = "";
  private list: Array<any> = [
    {
      country_code: "AF",
      inter_dialing: "+93"
    },
    {
      country_code: "AL",
      inter_dialing: "+355"
    },
    {
      country_code: "DZ",
      inter_dialing: "+213"
    },
    {
      country_code: "AS",
      inter_dialing: "+1-684"
    },
    {
      country_code: "AD",
      inter_dialing: "+376"
    },
    {
      country_code: "AO",
      inter_dialing: "+244"
    },
    {
      country_code: "AI",
      inter_dialing: "+1-264"
    },
    {
      country_code: "AQ",
      inter_dialing: "+672"
    },
    {
      country_code: "AG",
      inter_dialing: "+1-268"
    },
    {
      country_code: "AR",
      inter_dialing: "+54"
    },
    {
      country_code: "AM",
      inter_dialing: "+374"
    },
    {
      country_code: "AW",
      inter_dialing: "+297"
    },
    {
      country_code: "AU",
      inter_dialing: "+61"
    },
    {
      country_code: "AT",
      inter_dialing: "+43"
    },
    {
      country_code: "AZ",
      inter_dialing: "+994"
    },
    {
      country_code: "BS",
      inter_dialing: "+1-242"
    },
    {
      country_code: "BH",
      inter_dialing: "+973"
    },
    {
      country_code: "BD",
      inter_dialing: "+880"
    },
    {
      country_code: "BB",
      inter_dialing: "+1-246"
    },
    {
      country_code: "BY",
      inter_dialing: "+375"
    },
    {
      country_code: "BE",
      inter_dialing: "+32"
    },
    {
      country_code: "BZ",
      inter_dialing: "+501"
    },
    {
      country_code: "BJ",
      inter_dialing: "+229"
    },
    {
      country_code: "BM",
      inter_dialing: "+1-441"
    },
    {
      country_code: "BT",
      inter_dialing: "+975"
    },
    {
      country_code: "BO",
      inter_dialing: "+591"
    },
    {
      country_code: "BA",
      inter_dialing: "+387"
    },
    {
      country_code: "BW",
      inter_dialing: "+267"
    },
    {
      country_code: "BV",
      inter_dialing: ""
    },
    {
      country_code: "BR",
      inter_dialing: "+55"
    },
    {
      country_code: "IO",
      inter_dialing: ""
    },
    {
      country_code: "BN",
      inter_dialing: "+673"
    },
    {
      country_code: "BG",
      inter_dialing: "+359"
    },
    {
      country_code: "BF",
      inter_dialing: "+226"
    },
    {
      country_code: "BI",
      inter_dialing: "+257"
    },
    {
      country_code: "KH",
      inter_dialing: "+855"
    },
    {
      country_code: "CM",
      inter_dialing: "+237"
    },
    {
      country_code: "CA",
      inter_dialing: "+1"
    },
    {
      country_code: "CV",
      inter_dialing: "+238"
    },
    {
      country_code: "KY",
      inter_dialing: "+1-345"
    },
    {
      country_code: "CF",
      inter_dialing: "+236"
    },
    {
      country_code: "TD",
      inter_dialing: "+235"
    },
    {
      country_code: "CL",
      inter_dialing: "+56"
    },
    {
      country_code: "CN",
      inter_dialing: "+86"
    },
    {
      country_code: "CX",
      inter_dialing: "+53"
    },
    {
      country_code: "CC",
      inter_dialing: "+61"
    },
    {
      country_code: "CO",
      inter_dialing: "+57"
    },
    {
      country_code: "KM",
      inter_dialing: "+269"
    },
    {
      country_code: "CD",
      inter_dialing: "+243"
    },
    {
      country_code: "CG",
      inter_dialing: "+242"
    },
    {
      country_code: "CK",
      inter_dialing: "+682"
    },
    {
      country_code: "CR",
      inter_dialing: "+506"
    },
    {
      country_code: "CI",
      inter_dialing: "+225"
    },
    {
      country_code: "HR",
      inter_dialing: "+385"
    },
    {
      country_code: "CU",
      inter_dialing: "+53"
    },
    {
      country_code: "CY",
      inter_dialing: "+357"
    },
    {
      country_code: "CZ",
      inter_dialing: "+420"
    },
    {
      country_code: "CS",
      inter_dialing: ""
    },
    {
      country_code: "DK",
      inter_dialing: "+45"
    },
    {
      country_code: "DJ",
      inter_dialing: "+253"
    },
    {
      country_code: "DM",
      inter_dialing: "+1-767"
    },
    {
      country_code: "DO",
      inter_dialing: "1-829� "
    },
    {
      country_code: "TP",
      inter_dialing: "+670"
    },
    {
      country_code: "EC",
      inter_dialing: "+593 "
    },
    {
      country_code: "EG",
      inter_dialing: "+20"
    },
    {
      country_code: "SV",
      inter_dialing: "+503"
    },
    {
      country_code: "GQ",
      inter_dialing: "+240"
    },
    {
      country_code: "ER",
      inter_dialing: "+291"
    },
    {
      country_code: "EE",
      inter_dialing: "+372"
    },
    {
      country_code: "ET",
      inter_dialing: "+251"
    },
    {
      country_code: "FK",
      inter_dialing: "+500"
    },
    {
      country_code: "FO",
      inter_dialing: "+298"
    },
    {
      country_code: "FJ",
      inter_dialing: "+679"
    },
    {
      country_code: "FI",
      inter_dialing: "+358"
    },
    {
      country_code: "FR",
      inter_dialing: "+33"
    },
    {
      country_code: "GF",
      inter_dialing: "+594"
    },
    {
      country_code: "PF",
      inter_dialing: "+689"
    },
    {
      country_code: "TF",
      inter_dialing: ""
    },
    {
      country_code: "GA",
      inter_dialing: "+241"
    },
    {
      country_code: "GM",
      inter_dialing: "+220"
    },
    {
      country_code: "GE",
      inter_dialing: "+995"
    },
    {
      country_code: "DE",
      inter_dialing: "+49"
    },
    {
      country_code: "GH",
      inter_dialing: "+233"
    },
    {
      country_code: "GI",
      inter_dialing: "+350"
    },
    {
      country_code: "GB",
      inter_dialing: ""
    },
    {
      country_code: "GR",
      inter_dialing: "+30"
    },
    {
      country_code: "GL",
      inter_dialing: "+299"
    },
    {
      country_code: "GD",
      inter_dialing: "+1-473"
    },
    {
      country_code: "GP",
      inter_dialing: "+590"
    },
    {
      country_code: "GU",
      inter_dialing: "+1-671"
    },
    {
      country_code: "GT",
      inter_dialing: "+502"
    },
    {
      country_code: "GN",
      inter_dialing: "+224"
    },
    {
      country_code: "GW",
      inter_dialing: "+245"
    },
    {
      country_code: "GY",
      inter_dialing: "+592"
    },
    {
      country_code: "HT",
      inter_dialing: "+509"
    },
    {
      country_code: "HM",
      inter_dialing: ""
    },
    {
      country_code: "VA",
      inter_dialing: ""
    },
    {
      country_code: "HN",
      inter_dialing: "+504"
    },
    {
      country_code: "HK",
      inter_dialing: "+852"
    },
    {
      country_code: "HU",
      inter_dialing: "+36"
    },
    {
      country_code: "IS",
      inter_dialing: "+354"
    },
    {
      country_code: "IN",
      inter_dialing: "+91"
    },
    {
      country_code: "ID",
      inter_dialing: "+62"
    },
    {
      country_code: "IR",
      inter_dialing: "+98"
    },
    {
      country_code: "IQ",
      inter_dialing: "+964"
    },
    {
      country_code: "IE",
      inter_dialing: "+353"
    },
    {
      country_code: "IL",
      inter_dialing: "+972"
    },
    {
      country_code: "IT",
      inter_dialing: "+39"
    },
    {
      country_code: "JM",
      inter_dialing: "+1-876"
    },
    {
      country_code: "JP",
      inter_dialing: "+81"
    },
    {
      country_code: "JO",
      inter_dialing: "+962"
    },
    {
      country_code: "KZ",
      inter_dialing: "+7"
    },
    {
      country_code: "KE",
      inter_dialing: "+254"
    },
    {
      country_code: "KI",
      inter_dialing: "+686"
    },
    {
      country_code: "KP",
      inter_dialing: "+850"
    },
    {
      country_code: "KR",
      inter_dialing: "+82"
    },
    {
      country_code: "KW",
      inter_dialing: "+965"
    },
    {
      country_code: "KG",
      inter_dialing: "+996"
    },
    {
      country_code: "LA",
      inter_dialing: "+856"
    },
    {
      country_code: "LV",
      inter_dialing: "+371"
    },
    {
      country_code: "LB",
      inter_dialing: "+961"
    },
    {
      country_code: "LS",
      inter_dialing: "+266"
    },
    {
      country_code: "LR",
      inter_dialing: "+231"
    },
    {
      country_code: "LY",
      inter_dialing: "+218"
    },
    {
      country_code: "LI",
      inter_dialing: "+423"
    },
    {
      country_code: "LT",
      inter_dialing: "+370"
    },
    {
      country_code: "LU",
      inter_dialing: "+352"
    },
    {
      country_code: "MO",
      inter_dialing: "+853"
    },
    {
      country_code: "MK",
      inter_dialing: "+389"
    },
    {
      country_code: "MG",
      inter_dialing: "+261"
    },
    {
      country_code: "MW",
      inter_dialing: "+265"
    },
    {
      country_code: "MY",
      inter_dialing: "+60"
    },
    {
      country_code: "MV",
      inter_dialing: "+960"
    },
    {
      country_code: "ML",
      inter_dialing: "+223"
    },
    {
      country_code: "MT",
      inter_dialing: "+356"
    },
    {
      country_code: "MH",
      inter_dialing: "+692"
    },
    {
      country_code: "MQ",
      inter_dialing: "+596"
    },
    {
      country_code: "MR",
      inter_dialing: "+222"
    },
    {
      country_code: "MU",
      inter_dialing: "+230"
    },
    {
      country_code: "YT",
      inter_dialing: "+269"
    },
    {
      country_code: "MX",
      inter_dialing: "+52"
    },
    {
      country_code: "FM",
      inter_dialing: "+691"
    },
    {
      country_code: "MD",
      inter_dialing: "+373"
    },
    {
      country_code: "MC",
      inter_dialing: "+377"
    },
    {
      country_code: "MN",
      inter_dialing: "+976"
    },
    {
      country_code: "MS",
      inter_dialing: "+1-664"
    },
    {
      country_code: "MA",
      inter_dialing: "+212"
    },
    {
      country_code: "MZ",
      inter_dialing: "+258"
    },
    {
      country_code: "MM",
      inter_dialing: "+95"
    },
    {
      country_code: "NA",
      inter_dialing: "+264"
    },
    {
      country_code: "NR",
      inter_dialing: "+674"
    },
    {
      country_code: "NP",
      inter_dialing: "+977"
    },
    {
      country_code: "NL",
      inter_dialing: "+31"
    },
    {
      country_code: "AN",
      inter_dialing: "+599"
    },
    {
      country_code: "NC",
      inter_dialing: "+687"
    },
    {
      country_code: "NZ",
      inter_dialing: "+64"
    },
    {
      country_code: "NI",
      inter_dialing: "+505"
    },
    {
      country_code: "NE",
      inter_dialing: "+227"
    },
    {
      country_code: "NG",
      inter_dialing: "+234"
    },
    {
      country_code: "NU",
      inter_dialing: "+683"
    },
    {
      country_code: "NF",
      inter_dialing: "+672"
    },
    {
      country_code: "MP",
      inter_dialing: "+1-670"
    },
    {
      country_code: "NO",
      inter_dialing: "+47"
    },
    {
      country_code: "OM",
      inter_dialing: "+968"
    },
    {
      country_code: "PK",
      inter_dialing: "+92"
    },
    {
      country_code: "PW",
      inter_dialing: "+680"
    },
    {
      country_code: "PS",
      inter_dialing: "+970"
    },
    {
      country_code: "PA",
      inter_dialing: "+507"
    },
    {
      country_code: "PG",
      inter_dialing: "+675"
    },
    {
      country_code: "PY",
      inter_dialing: "+595"
    },
    {
      country_code: "PE",
      inter_dialing: "+51"
    },
    {
      country_code: "PH",
      inter_dialing: "+63"
    },
    {
      country_code: "PN",
      inter_dialing: ""
    },
    {
      country_code: "PL",
      inter_dialing: "+48"
    },
    {
      country_code: "PT",
      inter_dialing: "+351"
    },
    {
      country_code: "PR",
      inter_dialing: "+1-939"
    },
    {
      country_code: "QA",
      inter_dialing: "+974 "
    },
    {
      country_code: "RE",
      inter_dialing: "+262"
    },
    {
      country_code: "RO",
      inter_dialing: "+40"
    },
    {
      country_code: "SU",
      inter_dialing: ""
    },
    {
      country_code: "RU",
      inter_dialing: "+7"
    },
    {
      country_code: "RW",
      inter_dialing: "+250"
    },
    {
      country_code: "SH",
      inter_dialing: "+290"
    },
    {
      country_code: "KN",
      inter_dialing: "+1-869"
    },
    {
      country_code: "LC",
      inter_dialing: "+1-758"
    },
    {
      country_code: "PM",
      inter_dialing: "+508"
    },
    {
      country_code: "VC",
      inter_dialing: "+1-784"
    },
    {
      country_code: "WS",
      inter_dialing: "+685"
    },
    {
      country_code: "SM",
      inter_dialing: "+378"
    },
    {
      country_code: "ST",
      inter_dialing: "+239"
    },
    {
      country_code: "SA",
      inter_dialing: "+966"
    },
    {
      country_code: "RS",
      inter_dialing: ""
    },
    {
      country_code: "SN",
      inter_dialing: "+221"
    },
    {
      country_code: "SC",
      inter_dialing: "+248"
    },
    {
      country_code: "SL",
      inter_dialing: "+232"
    },
    {
      country_code: "SG",
      inter_dialing: "+65"
    },
    {
      country_code: "SK",
      inter_dialing: "+421"
    },
    {
      country_code: "SI",
      inter_dialing: "+386"
    },
    {
      country_code: "SB",
      inter_dialing: "+677"
    },
    {
      country_code: "SO",
      inter_dialing: "+252"
    },
    {
      country_code: "ZA",
      inter_dialing: "+27"
    },
    {
      country_code: "GS",
      inter_dialing: ""
    },
    {
      country_code: "ES",
      inter_dialing: "+34"
    },
    {
      country_code: "LK",
      inter_dialing: "+94"
    },
    {
      country_code: "SD",
      inter_dialing: "+249"
    },
    {
      country_code: "SR",
      inter_dialing: "+597"
    },
    {
      country_code: "SJ",
      inter_dialing: ""
    },
    {
      country_code: "SZ",
      inter_dialing: "+268"
    },
    {
      country_code: "SE",
      inter_dialing: "+46"
    },
    {
      country_code: "CH",
      inter_dialing: "+41"
    },
    {
      country_code: "SY",
      inter_dialing: "+963"
    },
    {
      country_code: "TW",
      inter_dialing: "+886"
    },
    {
      country_code: "TJ",
      inter_dialing: "+992"
    },
    {
      country_code: "TZ",
      inter_dialing: "+255"
    },
    {
      country_code: "TH",
      inter_dialing: "+66"
    },
    {
      country_code: "TG",
      inter_dialing: ""
    },
    {
      country_code: "TK",
      inter_dialing: "+690"
    },
    {
      country_code: "TO",
      inter_dialing: "+676"
    },
    {
      country_code: "TT",
      inter_dialing: "+1-868"
    },
    {
      country_code: "TE",
      inter_dialing: ""
    },
    {
      country_code: "TN",
      inter_dialing: "+216"
    },
    {
      country_code: "TR",
      inter_dialing: "+90"
    },
    {
      country_code: "TM",
      inter_dialing: "+993"
    },
    {
      country_code: "TC",
      inter_dialing: "+1-649"
    },
    {
      country_code: "TV",
      inter_dialing: "+688"
    },
    {
      country_code: "UG",
      inter_dialing: "+256"
    },
    {
      country_code: "UA",
      inter_dialing: "+380"
    },
    {
      country_code: "AE",
      inter_dialing: "+971"
    },
    {
      country_code: "GB",
      inter_dialing: "+44"
    },
    {
      country_code: "US",
      inter_dialing: "+1"
    },
    {
      country_code: "UM",
      inter_dialing: ""
    },
    {
      country_code: "UY",
      inter_dialing: "+598"
    },
    {
      country_code: "UZ",
      inter_dialing: "+998"
    },
    {
      country_code: "VU",
      inter_dialing: "+678"
    },
    {
      country_code: "VA",
      inter_dialing: "+418"
    },
    {
      country_code: "VE",
      inter_dialing: "+58"
    },
    {
      country_code: "VN",
      inter_dialing: "+84"
    },
    {
      country_code: "VI",
      inter_dialing: "+1-284"
    },
    {
      country_code: "VQ",
      inter_dialing: "+1-340"
    },
    {
      country_code: "WF",
      inter_dialing: "+681"
    },
    {
      country_code: "EH",
      inter_dialing: ""
    },
    {
      country_code: "YE",
      inter_dialing: "+967"
    },
    {
      country_code: "YU",
      inter_dialing: ""
    },
    {
      country_code: "ZR",
      inter_dialing: ""
    },
    {
      country_code: "ZM",
      inter_dialing: "+260"
    },
    {
      country_code: "ZW",
      inter_dialing: "+263"
    }
  ];

  @Ref() form!: RequestDemoForm;

  constructor() {
    super();
  }

  mounted() {
    this.element = this.$el;
    this.isLoaded = true;
    console.log(process.env);
  }

  @Watch("windowtop", { immediate: true, deep: true })
  onScrollChanged(val: number, oldVal: string) {
    if (this.isLoaded) {
      this.checkScrollTop(val, this);
    }
  }

  phoneCode() {
    let size = this.list.length;
    let country_code = this.country_code;
    let result = this.list;
    let i = 0;
    for (i; i < size; i++) {
      if (country_code == result[i].country_code) {
        return (this.phone_code = result[i].inter_dialing);
      }
    }
  }

  get windowtop(): VForm {
    return this.root.windowtop;
  }

  get content(): string {
    const message = `customer name: ${this.customername}, 
              business name: ${this.business},
              mobile: ${this.mobile},
              email: ${this.email}`;
    return message;
  }

  async requestDemo(e: any) {

    this.$v.$touch();

    if (this.$v.$anyError) {
      return this.$toasted.global.error({
        message: "Error! sorry your request is invalid!"
      });
    }

    const defaultText = e.target.innerHTML;

    e.target.innerHTML =
      '<i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Sending email ...';

    e.target.disabled = true;

    let data = {
      Host: "smtp.sendgrid.net",
      Username: "apikey",
      Password:
        "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",
      To: "sales@getsolo.io",
      From: "jmatias@skylinedynamics.com",
      Subject: "Request for Demo",
      Body: this.content,
      Action: "Send"
    };

    sendGrid.send(data).then((response: any) => {
      this.$v.$reset();
      e.target.innerHTML = defaultText;
      e.target.disabled = false;
      this.customername = "";
      this.business = "";
      this.mobile = "";
      this.email = "";
      this.form.reset();
      this.$toasted.global.info({
        message: "Thanks! your request demo email sent!"
      });
    });
  }

  formToJSON(elements: HTMLFormControlsCollection) {
    return [].reduce.call(
      elements,
      (data: any, element: any) => {
        if (element.name != "") data[element.name] = element.value;
        return data;
      },
      {}
    );
  }

  checkScrollTop(parentScroll: number = 0, componentScroll: any) {
    if (
      parentScroll >= componentScroll.$el.offsetTop - this.deductionOnTop &&
      parentScroll <=
        componentScroll.$el.offsetTop +
          componentScroll.$el.clientHeight -
          this.deductionOnBottom
    )
      this.animate(parentScroll, componentScroll.offsetTop);
    else this.stopAnimation();
  }

  animate(parentScroll: number, componentScroll: number) {
    this.animateSection = true;
    this.hideContent = false;
  }

  stopAnimation() {
    this.animateSection = false;
    this.hideContent = true;
  }
}
