import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import AllPricing from "../components/AllPricing.vue";
import Integration from "../components/AllIntegration.vue";
import SoloWeb from "../components/AllSocialMediaOrdering.vue";
import SoloKiosk from "../components/AllSoloKiosk.vue";
import SoloApp from "../components/AllSoloApp.vue";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,

  },
  {
    path: "/pricing",
    name: "Pricing",
    component: AllPricing,
  },
  {
    path: "/integration",
    name: "Integration",
    component: Integration,
  },
  {
    path: "/solo-web",
    name: "SoloWeb",
    component: SoloWeb,
  },
  {
    path: "/solo-kiosk",
    name: "SoloKiosk",
    component: SoloKiosk,
  },
  {
    path: "/solo-app",
    name: "SoloApp",
    component: SoloApp,
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

Vue.use(VueGtag, {
  config: { id: "UA-163324904-2" }
}, router);

export default router;
