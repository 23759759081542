
















































































































































































































import Body1 from "@/components/BodyIntegration.vue";
import Header1 from "@/components/Header1.vue";
import Footer from "@/components/Footer1.vue";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref
} from "vue-property-decorator";

@Component({
  components: {
    Body1,
    Header1,
    Footer
  }
})
export default class Home extends Vue {

    @Ref() header1!: HTMLElement;
    @Ref() footer!: HTMLElement;
}
