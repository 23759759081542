<template>
  <div class="solo-body1" v-if="!isMobile">
    <div class="circle-one orange-one"></div> 
    <div class="circle-two orange-two"></div>  
    <div class="circle-three orange-three"></div>  
    <div class="circle-four orange-four"></div>
    <div class="circle-five orange-five"></div> 
    <div class="circle-six orange-six"></div>
    <div class="circle-seven orange-seven"></div>
    <div class="circle-eight orange-eight"></div>
    <div class="circle-nine orange-nine"></div>
    <div class="circle-ten orange-ten"></div>
    <div class="circle-eleven orange-eleven"></div>
    <div class="circle-twelve orange-twelve"></div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>