


















































































import { VForm } from "@/types";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  ProvideReactive,
  InjectReactive,
  Ref,
  Watch
} from "vue-property-decorator";

@Component
export default class SocialMediaOrdering extends Vue {
  isPlaying: boolean = true;
  root: any = this.$root;
  element: any;
  isLoaded: boolean = false;
  animateSection: boolean = false;
  hideContent: boolean = true;
  deductionOnTop: number = 400;
  deductionOnBottom: number = 200;
  isActive: boolean = false;
  animateContent: Boolean = true;
  @Ref() videoLayer!: HTMLVideoElement;

  mounted() {
    this.element = this.$el;
    this.isLoaded = true;
    this.topLayerOnClick();
  }

  @Watch("windowtop", { immediate: true, deep: true })
  onScrollChanged(val: number, oldVal: string) {
    if (this.isLoaded) {
      this.checkScrollTop(val, this);
    }
  }

  get windowtop(): VForm {
    return this.root.windowtop;
  }

  checkScrollTop(parentScroll: number = 0, componentScroll: any) {
    if (
      parentScroll >= componentScroll.$el.offsetTop - this.deductionOnTop &&
      parentScroll <=
        componentScroll.$el.offsetTop +
          componentScroll.$el.clientHeight -
          this.deductionOnBottom
    ) {
      this.isActive = true;
      if (this.animateContent) {
        this.animate(parentScroll, componentScroll.offsetTop);
        this.animateContent = false;
      } else {
        this.stopAnimation();
      }
    } else {
      this.isActive = false;
    }
  }

  animate(parentScroll: number, componentScroll: number) {
    this.animateSection = true;
    this.hideContent = false;

    this.play();
  }

  stopAnimation() {
    this.animateSection = false;
    this.hideContent = true;

    this.play();
  }

  play() {
    this.videoLayer.play();
    this.isPlaying = true;
  }

  pause() {
    this.videoLayer.pause();
    this.isPlaying = false;
  }
  topLayerOnClick() {
    if (!this.isPlaying) {
      this.play();
    } else {
      this.pause();
    }
  }
}
