<template>
  <div id="app">
    <transition name="fade" v-on:enter="enter">
      <div v-if="show" class="loader"><p class="loader_text">Loading</p></div>
    </transition>
    <router-view ref="router" />
  </div>
</template>
<style lang="scss">
@import "./assets/sass/app.scss";
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../public/loader/loader1.gif) center no-repeat #ff6b00;
  .loader_text {
    position: fixed;
    left: 0px;
    top: 53%;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
    letter-spacing: 2px;
    font-size: 38px;
    color: #fff;
    font-family: "Almarai", sans-serif;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
<script>
export default {
  data() {
    return {
      show: false
    };
  },
  mounted() {},
  methods: {
    fadeMe: function() {
      this.show = !this.show;
    },

    enter: function(el, done) {
      var that = this;

      setTimeout(function() {
        that.show = false;
      }, 3000); // hide the message after 3 seconds
    }
  }
};
</script>
