<template>
  <div class="solo-body1" v-if="!isMobile">
    <!-- <div class="circle-one orange-one link link-two shrink-on-hover"></div> -->
    <!-- <div class="circle-two orange-two link link-two shrink-on-hover"></div> -->
    <div class="circle-three orange-three link link-two shrink-on-hover"></div>
    <div class="circle-four orange-four link link-two shrink-on-hover"></div>
    <div class="circle-five orange-five link link-two shrink-on-hover"></div> 
    <div class="circle-six orange-six link link-two shrink-on-hover"></div>
    <div class="circle-seven orange-seven link link-two shrink-on-hover"></div>
    <div class="circle-eight orange-eight link link-two shrink-on-hover"></div>
    <div class="circle-nine orange-nine link link-two shrink-on-hover"></div>
    <div class="circle-ten orange-ten link link-two shrink-on-hover"></div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>