export default {
    setUser(state: { user: any; }, data: any) {
        state.user = data;
    },
    logoutUser(state: { user: null; }) {
        state.user = null;
    },

    setUserAddresses(state: { user: { addresses: any; }; }, data: any) {
        state.user.addresses = data
    },

    setEmail(state: { user: { attributes: { email: any; }; }; }, data: any) {
        state.user.attributes.email = data;
    },

    setFirstName(state: { user: { [x: string]: any; }; }, data: any) {
        state.user.attributes['first-name'] = data;
    },

    setLastName(state: { user: { [x: string]: any; }; }, data: any) {
        state.user.attributes['last-name'] = data;
    },

    setMobile(state: { user: { mobile: any; }; }, data: any) {
        state.user.mobile = data;
    },

    setStatus(state: { user: { status: any; }; }, data: any) {
        state.user.status = data
    }
}
