import { render, staticRenderFns } from "./AllPricing.vue?vue&type=template&id=703e0e45&scoped=true&"
import script from "./AllPricing.vue?vue&type=script&lang=ts&"
export * from "./AllPricing.vue?vue&type=script&lang=ts&"
import style0 from "./AllPricing.vue?vue&type=style&index=0&id=703e0e45&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703e0e45",
  null
  
)

export default component.exports