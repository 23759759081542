



































































import { VForm } from "@/types";
import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref,
  Watch
} from "vue-property-decorator";
@Component
export default class OurIntegration extends Vue {
  root: any = this.$root;
  element: any;
  isDesktopDisplay: boolean = true;
  isMobileDisplay: boolean = false;
  isLoaded: boolean = false;
  animateSection: boolean = false;
  hideContent: boolean = true;
  deductionOnTop: number = 800;
  deductionOnBottom: number = 200;
  animateContent: Boolean = true;
  isActive: boolean = false;
  mounted() {
    this.element = this.$el;
    this.isLoaded = true;
    this.showIMG();
  }

  @Watch("windowtop", { immediate: true, deep: true })
  onScrollChanged(val: number, oldVal: string) {
    if (this.isLoaded) {
      this.checkScrollTop(val, this);
    }
  }

  get windowtop(): VForm {
    return this.root.windowtop;
  }

  checkScrollTop(parentScroll: number = 0, componentScroll: any) {
    if (
      parentScroll >= componentScroll.$el.offsetTop - this.deductionOnTop &&
      parentScroll <=
        componentScroll.$el.offsetTop +
          componentScroll.$el.clientHeight -
          this.deductionOnBottom
    ) {
      this.isActive = true;
      if (this.animateContent) {
        this.animate(parentScroll, componentScroll.offsetTop);
        this.animateContent = false;
      } else {
        this.stopAnimation();
      }
    } else {
      this.isActive = false;
    }
  }

  animate(parentScroll: number, componentScroll: number) {
    this.animateSection = true;
    this.hideContent = false;
  }

  showIMG() {
    if (!this.isMobile()) {
      this.isDesktopDisplay = true;
      this.isMobileDisplay = false;
    } else {
      this.isDesktopDisplay = false;
      this.isMobileDisplay = true;
    }
  }

  stopAnimation() {
    this.animateSection = false;
    this.hideContent = true;
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
