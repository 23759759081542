import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@/assets/plugins/vue-toasted'
import '@/assets/plugins/vuelidate'
import CountryFlag from 'vue-country-flag'
import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false;

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-163324904-2" }
});

new Vue({
  data() {
    return {
      windowtop: 0
    }
  },
  router,
  store,
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll() {
      this.windowtop = document.documentElement.scrollTop
    }
  },
  render: h => h(App),
}).$mount("#app");
