export default {
    getUser(state: { user: any; }) {
        return state.user;
    },
    isLoggedIn(state: { user: null; }) {
        return state.user !== null;
    },
    getUserAddresses(state: { user: { addresses: any; }; }) {
        if (state.user) {
            if(state.user.addresses)
            {
                return state.user.addresses;
            }            
            return []
        }

        return [];
    },
    getFirstName(state: { user: { attributes: { [x: string]: any; }; }; }) {
        return state.user.attributes['first-name']
    },
    getLastName(state: { user: { attributes: { [x: string]: any; }; }; }) {
        return state.user.attributes['last-name']
    },    
    getEmail(state: { user: { attributes: { email: any; }; }; }) {
        return state.user.attributes.email;
    },
    getMobile(state: { user: { attributes: { mobile: any; }; }; }) {
        return state.user.attributes.mobile;
    },
    getStatus(state: { user: { attributes: { status: any; }; }; }) {
        return state.user.attributes.status;
    }
}
