





























































































































































































































import {
  Component,
  Prop,
  Vue,
  Inject,
  Provide,
  Ref
} from "vue-property-decorator";
@Component
export default class Footer extends Vue {
  refs!: any;
  isDesktopDisplay: boolean = false;
  isMobileDisplay: boolean = false;
  constructor() {
    super();
    this.refs = this.$parent.$refs;
  }
  mounted() {
    this.showFooter();
  }

  showFooter() {
    if (!this.isMobile()) {
      this.isDesktopDisplay = true;
      this.isMobileDisplay = false;
    } else {
      this.isDesktopDisplay = false;
      this.isMobileDisplay = true;
    }
  }

  get introductionIsActive(): boolean {
    return this.refs.introduction.isActive;
  }

  get socialIsActive(): boolean {
    return this.refs.social.isActive;
  }

  get soloappIsActive(): boolean {
    return this.refs.soloApp.isActive;
  }

  get soloKioskIsActive(): boolean {
    return this.refs.soloKiosk.isActive;
  }

  get clientsIsActive(): boolean {
    return this.refs.clients.isActive;
  }

  get integrationIsActive(): boolean {
    return this.refs.integration.isActive;
  }

  scrollTO(e: HTMLFormElement, num: number = 200) {
    if (window.screen.width >= 1200) {
      window.scrollTo({
        top: e.$el.offsetTop - num,
        left: 0,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({
        top: e.$el.offsetTop + num,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
