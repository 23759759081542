<template>
  <div class="solo-body1" v-if="!isMobile">
    <div class="circle-one orange-one"></div> 
    <div class="circle-two orange-two"></div>  
    <div class="circle-three orange-three"></div>  
    <div class="circle-four orange-four"></div>
    <div class="circle-eight orange-eight"></div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>